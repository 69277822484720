import "./App.css";

import Landingpage from './Landingpage/Landingpage';
import Project from "./Projects/Projects";
import Footer from "./Footer/Footer";

function App() {
  return (
  <div className="App">
  <Landingpage/>
  <Project/>
  <Footer/> 
  </div>
  )
}

export default App;
