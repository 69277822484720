import React from "react";
import "./card.css";

const Card = (props) => {
  return (
    <>
    <div className="card">
      <div className="card_color">
        <h2 style={{color: props.pnameColor}}>{props.pname}</h2>
        <p>{props.pdes}</p>
      </div>
      <div className="card_title">
        <h2>{props.title}</h2>
        <p>
         {props.titledes}
        </p>
      </div>
      <div className="card_button">
          <a href={props.cardAddress} target="_blank"><button>Check project</button></a>
      </div>
      </div>
    </>
  );
};

export default Card;
