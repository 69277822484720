const AllProjectData = [
	{
		upname: "Dart Standard Library",
		updescription: "Get All Kotlin Standard Libs in Dart",
		namecolor: "rgb(255, 123, 0)",
		lowtitle: "Flutter, Dart",
		lowdescription:
			"Package for Flutter, which can lessen your boilerplate code. It has many built in functions and extension which can be used...",
		address: "https://github.com/debojyoti452/dart_standard_lib",
	},
	{
		upname: "MoveIt - File Transfer On Go",
		updescription: "File Sharing App for all Platform (Windows, Linux, Mac, Android, iOS).",
		namecolor: "#c2fffa",
		lowtitle: "Flutter, Swift, Kotlin",
		lowdescription:
			"MoveIt - File Transfer On Go is a File Sharing App for all Platform (Windows, Linux, Mac, Android, iOS). It can be used as a replacement for AirDrop. A lot of people are using AirDrop to share files between their devices. But, AirDrop is only available for Mac and iOS. So, I created MoveIt - File Transfer On Go to share files between all devices.",
		address: "https://github.com/debojyoti452/move_it_file_transfer",
	},
	{
		upname: "Encrypt Local Storage",
		updescription: "Local Storage that stores data as encrypted form.",
		namecolor: "blue",
		lowtitle: "Flutter, Swift, Kotlin",
		lowdescription:
			"A Flutter plugin to encrypt and decrypt data using AES-256 encryption algorithm. You can use this plugin to store sensitive data in the local storage. eg. User credentials, API keys, Bearer Token etc.",
		address: "https://github.com/debojyoti452/encrypt_local_storage",
	},
	{
		upname: "OneStore [OFFICIAL]",
		updescription: "Safe storage of your CreditCards, DebitCards, etc.",
		bgColor: "#c2fffa",
		lowtitle: "Flutter, Dart",
		lowdescription:
			"This is a card store for the OneStore App. It is a simple app that allows you to create a store for your cards. You can keep your card safe and secure in the app.",
		address: "https://github.com/debojyoti452/onestore-app",
	},
	{
		upname: "Github Login SDK for Android (LightWeight)",
		updescription: "A Working Prototype of Zoom Calling App",
		bgColor: "#c2fffa",
		lowtitle: "Android, Kotlin, Clean Architecture",
		lowdescription:
			" As all we know that Github SDK is already available in Firebase Auth SDK but it has limitation like using custom redirect url can't be used or setting scopes is hard.",
		address: "https://github.com/debojyoti452/GithubLoginSDK",
	},
	{
		upname: "MedRelief",
		updescription: "Working Online Medicine & Doctor Booking App",
		namecolor: "blue",
		lowtitle: "Android, Kotlin",
		lowdescription:
			"MedRelief brings you a relief from the hectic events in booking Doctor Appointment.",
		address:
			"https://play.google.com/store/apps/details?id=com.dev452.medrelief",
	},
	{
		upname: "ShareNEXT",
		updescription: "Working File Transfer using bluetooth & WiFi",
		bgColor: "rgb(255, 203, 218)",
		lowtitle: "Android, Kotlin",
		lowdescription:
			"Share NEXT is the first biggest File Sharing App Made in India - Share Files, Music, Videos, APKs of apps, Photos and anything you need.",
		address:
			"https://play.google.com/store/apps/details?id=com.swing.shareNext",
	},
	{
		upname: "DailyCart",
		updescription: "Working demo of E-Commerce Delivery App",
		bgColor: "#d6ffca",
		lowtitle: "Android, Kotlin",
		lowdescription:
			"DailyCart App is an online all-in-one shopping app where you can buy everything that you need in your day to day life starting from grocery, beauty, health & hygiene, baby products to stationary items.",
		address:
			"https://play.google.com/store/apps/details?id=com.swing.daily_cart",
	},
];

export default AllProjectData;
