import React from "react";
import "./allcard.css";

const AllCard = (props) => {
  return (
    <>
    <div className="allcard">
      <div className="allcard_color" style={{backgroundColor: props.color}}>
        <h2 style={{color: props.titleColor}}>{props.pname}</h2>
        <p>{props.pdes}</p>
      </div>
      <div className="allcard_title">
        <h2>{props.title}</h2>
        <p>
          {props.titledes}
        </p>
      </div>
      <div className="allcard_button">
          <a href={props.cardAddress} target="_blank"><button>Check project</button></a>
      </div>
      </div>
    </>
  );
};

export default AllCard;
