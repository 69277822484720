import React from "react";

import "./header.css";

import { motion } from "framer-motion";

const Header = () => {
  var items = document.getElementsByClassName("item");
  var activeClassName = "active";

  function unselectItems() {
    for (var i = 0; i < items.length; i++) {
      items[i].classList.remove(activeClassName);
    }
  }

  function selectItem(item) {
    unselectItems();
    item.classList.add(activeClassName);
  }

  function onItemClick(event) {
    selectItem(event.target);
  }

  for (var i = 0; i < items.length; i++) {
    items[i].addEventListener("click", onItemClick);
  }


  const fadeInDown = { 
    initial: {
      y:-60,
      opacity: 0,
    },

    animate: {
      y:0,
      opacity: 1,

      transition: {
        duration: 0.3,
        delay:2,
        ease: "easeInOut",
      },
    },
  };

  return (
    <>
      <motion.div 
      variants={fadeInDown}
      initial="initial"
      animate="animate"
     
      className="navbar">
        <div className="logo">
          <div className="logo_title">
            <h2>
              Open Source <span>Portfolio</span>
            </h2>
          </div>
          {/* <div className="logo_name">
            <p>DEBOJYOTI SINGHA</p>
          </div> */}
        </div>

        {/* <div className="initials">
          <p>D.S</p>
        </div> */}

        <div className="navbar_menu">
          <div className="navbar_menu_items">
            <p>
              <a className="item" href="#home">
                HOME
              </a>
            </p>
          </div>
          <div className="navbar_menu_items">
            <p>
              <a className="item" href="#projects">
                PROJECTS
              </a>
            </p>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Header;
