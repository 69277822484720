import React from "react";
import "./footer.css";

import {CgCoffee} from 'react-icons/cg';
import { HiMail } from "react-icons/hi";
import { RiLinkedinFill } from "react-icons/ri";
import {TiSocialTwitter } from "react-icons/ti";
import {RiGithubFill} from 'react-icons/ri';

const Footer = () => {
  return (
    <>
      <div className="footer section_padding">
        <div className="footer_name">
          <div className="text">
          <p>Get in touch with me</p>
          </div>
          <div className="icon">
            <CgCoffee style={{ color: "white", width:"1.5rem", height:"1.5rem" }}/>
          </div>
        </div>
        <div className="footer_contacts">
          <div className="email">
            <div className="icon">
              <p><HiMail style={{ color: "#5CDB95", width:"1.5rem", height:"1.5rem" }} /></p>
            </div>
            <div className="text">
            <p><a href="http://gmail.com/" target="_blank">support@swingtechnologies.in</a></p>
            </div>
          </div>

          <div className="github">
            <div className="icon">
              <RiGithubFill style={{ color: "#5CDB95", width:"1.5rem", height:"1.5rem" }} />
            </div>
            <div className="text">
            <p><a href="https://github.com/debojyoti452" target="_blank">Github</a></p>
            </div>
          </div>

          <div className="linkedin">
            <div className="icon">
              <RiLinkedinFill style={{ color: "#5CDB95", width:"1.5rem", height:"1.5rem" }} />
            </div>
            <div className="text">
            <p><a href="https://www.linkedin.com/in/debojyoti-singha-209aa7149/" target="_blank">Linkedin</a></p>
            </div>
          </div>

          <div className="twitter">
            <div className="icon">
              <TiSocialTwitter style={{ color: "#5CDB95", width:"1.5rem", height:"1.5rem" }} />
            </div>
            <div className="text">
            <p><a href="https://twitter.com/debojyotising13" target="_blank">Twitter</a></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
