import React from "react";
import "./planets.css";

import { motion } from "framer-motion";

const Planets = ({ classname, imgurl }) => {
  const imgAnimate = {
    initial: {},

    animate: {
      y: [30, 0, 30],
      transition: {
        duration: 3.6,
        ease: "linear",
        repeat: Infinity,
      },
    },
  };

  return (
    <>
      <div className="each_planet">
        <motion.img
          variants={imgAnimate}
          whileHover={{ scale: 0.5 }}
          initial="initial"
          animate="animate"

          className={classname}
          src={imgurl}
        />
      </div>
    </>
  );
};

export default Planets;
