import React from "react";
import "./landingpage.css";
import gif from "../assets/cubegif.gif";

import pla1 from "../assets/SVG/pla1.svg";
import pla2 from "../assets/SVG/pla2.svg";
import pla3 from "../assets/SVG/pla3.svg";
import pla4 from "../assets/SVG/pla4.svg";
import pla5 from "../assets/SVG/pla5.svg";

import { motion } from "framer-motion";

import Planets from "../Planets/Planets";

import Header from "../Header/Header";

const Landingpage = () => {
  const imgDivAnimate = {
    initial: {
      opacity: 0,
    },

    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const fadeInUp = {
    initial: {
      opacity: 0,
    },

    animate: {
      opacity: 1,

      transition: {
        duration: 1,
        delay: 1,
        ease: "easeInOut",
      },
    },
  };

  return (
    <>
      <Header />
      <div className="landingpage section_padding" id="home">
        <motion.div
          variants={imgDivAnimate}
          initial="initial"
          animate="animate"
          className="planets"
        >
          <Planets classname="pla3" imgurl={pla3} />
          <Planets classname="pla2" imgurl={pla2} />
          <Planets classname="pla1" imgurl={pla1} />
          <Planets classname="pla4" imgurl={pla4} />
          <Planets classname="pla5" imgurl={pla5} />
        </motion.div>

        <motion.div
          variants={fadeInUp}
          initial="initial"
          animate="animate"
          className="maintext_name"
        >
          <div className="uptext">
            <h5>Android - Flutter - iOS Swift - Backend Developer</h5>
          </div>
          <div className="middletext">
            <h2>DEBOJYOTI SINGHA</h2>
          </div>
          <div className="lowtext">
            <h6>Code - Coffee - Cosmos</h6>
          </div>
        </motion.div>
      </div>

      <div className="text_des section_padding">
        <div className="des">
          <p>
            <span className="helloworld">HELLO WORLD!</span>{" "}
            <span className="italicspan">I am</span>{" "}
            <span className="capitalitalic">ENTHUSIASTIC</span>{" "}
            <span className="italicspan">
              about developing new and innovative ideas into
            </span>{" "}
            <span className="capitalitalic">REALITY</span>{" "}
            <span className="italicspanwhite">which I often share on</span>{" "}
            <span className="opensource">OPEN SOURCE MEDIUM</span>{" "}
            <span className="italicspan">keeping them free to use.</span> Hope
            you browse through all my{" "}
            <span className="capitalitalic">PROJECTS</span>{" "}
            <span className="italicspanwhite">and find them</span>{" "}
            <span className="capitalitalic">USEFUL.</span>
          </p>
        </div>
        <div className="des_gif">
          <img src={gif} alt="" />
        </div>
      </div>
    </>
  );
};

export default Landingpage;
