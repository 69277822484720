import React from "react";
import './projects.css';

import RecentProjectData from "../API/RecentProjectData";

import Card from "../Cards/Card";
import AllCard from "../Cards/AllCard";
import AllProjectData from "../API/AllProjectData";

const Project=()=>{

    function recentProject(val){
        return(
            <Card pname={val.upname}
                  pdes={val.updescription}
                  pnameColor={val.namecolor}
                  title={val.lowtitle}
                  titledes={val.lowdescription}
                  cardAddress={val.address}/>
        )
    }


    function allProject(item){
        return(
        <AllCard pname={item.upname}
                 pdes={item.updescription}
                 color={item.bgColor}
                 title={item.lowtitle}
                 titledes={item.lowdescription}
                 cardAddress={item.address}
                 />
        )
    }


    return(
        <>
        <div className="projects section_padding" id="projects">
        <div className="projects_title">
            <h1>My Recent Projects</h1>
        </div>
        <div className="project_des">
           <p>Checkout my recent open source projects in Github repository.</p>
        </div>
        <div className="recent_cards">
            {RecentProjectData.map(recentProject)}
        </div>

        <div className="all_projects">
        <div className="allprojects_title">
            <h1>All Projects</h1>
        </div>
        <div className="all_cards">
            {AllProjectData.map(allProject)}
        </div>

        </div>
        </div>
        </>
    )
}

export default Project;