const RecentProjectData = [
	{
		upname: "Dart Standard Library",
		updescription: "Get All Kotlin Standard Libs in Dart",
		namecolor: "rgb(255, 123, 0)",
		lowtitle: "Flutter, Dart",
		lowdescription:
			"Package for Flutter, which can lessen your boilerplate code. It has many built in functions and extension which can be used...",
		address: "https://github.com/debojyoti452/dart_std",
	},
	{
		upname: "MoveIt - File Transfer On Go",
		updescription: "File Sharing App for all Platform (Windows, Linux, Mac, Android, iOS).",
		namecolor: "blue",
		lowtitle: "Flutter, Swift, Kotlin",
		lowdescription:
			"MoveIt - File Transfer On Go is a File Sharing App for all Platform (Windows, Linux, Mac, Android, iOS). It can be used as a replacement for AirDrop. A lot of people are using AirDrop to share files between their devices. But, AirDrop is only available for Mac and iOS. So, I created MoveIt - File Transfer On Go to share files between all devices.",
		address: "https://github.com/debojyoti452/move_it_file_transfer",
	}
];

export default RecentProjectData;
